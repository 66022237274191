import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Input } from "antd";
import {
  useGetAssignmentSubmissionsQuery,
  useGetAssignmentQuestionSubmissionsQuery,
} from "../submissionsApiSlice";
import { Alert, LoadingButton } from "@mui/lab";
import { LoadingOutlined } from "@ant-design/icons";
import QuestionSubmissionsList from "./questionSubmissionsList";
import { useGetAssignmentQuestionsQuery } from "../../questions/questionsApiSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addLmsRubric, setFilterByKey } from "../../lmsSlice";
import ByQuestionGradeAllDialog from "./byQuestionGradeAllDialog";
import {
  useCreateUpdateLmsCourseAssignmentQuestionRubricMutation,
  useGetAssignmentGradingStatusQuery,
  useGetLatestQuestionSubmissionsQuery,
  useGetLmsCourseAssignmentRubricsMutation,
  useLazyGetLatestQuestionSubmissionsQuery,
} from "../../../lmsManagement/lmsManagementApiSlice";
import GradingJobProgress from "../../../grader/gradingJobProgress";
import { useGetGradingQuestionSubmissionsJobQuery } from "../../../grader/graderApiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import DOMPurify from "dompurify";
import Typography from "@mui/material/Typography";
import { Breadcrumbs, Card, CardHeader } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadIcon from "@mui/icons-material/Upload";
import HomeIcon from "@mui/icons-material/Home";
import SubmitGradesDialog from "./submitGradesDialog";

const QuestionSubmissionsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const rubrics = useAppSelector((state) => state.lms.lmsRubrics);
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const questionID = useParams().questionID;

  const [rubric, setRubric] = useState(rubrics[+questionID!]);

  // const filterByKey = useAppSelector((state) => state.lms.filterByKey);

  // const graderResults = useAppSelector((state) => state.grader.graderResults);
  // const numGraded = 0;
  // Object.keys(graderResults
  // console.log(graderResults);

  const { TextArea } = Input;

  const {
    data: statusData,
    error: statusError,
    isLoading: statusIsLoading,
    isFetching: statusIsFetching,
    isSuccess: statusIsSuccess,
  } = useGetAssignmentGradingStatusQuery({
    lmsId: +lmsID!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: null,
  });

  const {
    data: questionData = {},
    error: questionEror,
    isLoading: questionIsLoading,
    isFetching: questionIsFetching,
    isSuccess: questionIsSuccess,
  } = useGetAssignmentQuestionsQuery({
    lms_type: workingLms && workingLms.lmsType!,
    base_url: workingLms && workingLms.lmsBaseUrl!,
    access_token: workingLms && workingLms.lmsAccessToken!,
    courseId: +courseID!,
    assignmentId: +assignmentID!,
  });

  const { data, refetch, error, isLoading, isFetching, isSuccess, isError } =
    useGetGradingQuestionSubmissionsJobQuery({
      lms_id: workingLms && workingLms.lmsId!,
      course_lms_id: +courseID!,
      assignment_lms_id: +assignmentID!,
      question_lms_id: +questionID!,
    });

  // useEffect(() => {
  //   // console.log(data);
  // }, [evaluateValue(+questionID!)]);

  const [GetLmsCourseAssignmentRubrics, GetLmsCourseAssignmentRubricsResults] =
    useGetLmsCourseAssignmentRubricsMutation();

  const [
    CreateUpdateLmsCourseAssignmentQuestionRubric,
    CreateUpdateLmsCourseAssignmentQuestionRubricResults,
  ] = useCreateUpdateLmsCourseAssignmentQuestionRubricMutation();

  useEffect(() => {
    // fetch the assignment rubrics on mount
    dispatch(setFilterByKey("byQuestion"));
    GetLmsCourseAssignmentRubrics({
      lmsId: workingLms && workingLms.lmsId!,
      courseLmsId: +courseID!,
      assignmentLmsId: +assignmentID!,
    });
  }, []);

  useEffect(() => {
    // set the rubric state for that question when fetch is complete
    dispatch(
      addLmsRubric({
        question_id: +questionID!,
        rubric: evaluateValue(+questionID!),
      })
    );
    setRubric(evaluateValue(+questionID!));
  }, [GetLmsCourseAssignmentRubricsResults.isSuccess]);

  function evaluateValue(questionId: number) {
    if (GetLmsCourseAssignmentRubricsResults.data) {
      const questionRubric = GetLmsCourseAssignmentRubricsResults.data!.find(
        (obj) => obj.question_lms_id === questionId
      );
      if (questionRubric) {
        return questionRubric.rubric;
      }
    }
    return rubrics[+questionID!];
  }

  const question = questionData[+questionID!];

  const onRubricChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // console.log(e);
    dispatch(
      addLmsRubric({ question_id: +questionID!, rubric: e.target.value })
    );
  };

  const saveRubric = () => {
    CreateUpdateLmsCourseAssignmentQuestionRubric({
      lmsId: workingLms && workingLms.lmsId!,
      courseLmsId: +courseID!,
      assignmentLmsId: +assignmentID!,
      questionLmsId: +questionID!,
      rubric: rubrics[+questionID!],
    }).then(() => {
      handleClickSnackbar();
      setRubric(rubrics[+questionID!]);
    });
  };

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
  });

  const {
    data: QuestionSubmissionsData,
    refetch: QuestionSubmissionsRefetch,
    error: QuestionSubmissionsEror,
    isLoading: QuestionSubmissionsIsLoading,
    isFetching: QuestionSubmissionsIsFetching,
    isSuccess: QuestionSubmissionsIsSuccess,
  } = useGetLatestQuestionSubmissionsQuery({
    lmsId: workingLms && workingLms.lmsId!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: +questionID!,
  });

  // const [GetLatestQuestionSubmissions, GetLatestQuestionSubmissionsResults] =
  //   useLazyGetLatestQuestionSubmissionsQuery();

  const handleDownload = () => {
    // GetLatestQuestionSubmissions({
    //   lmsId: workingLms && workingLms.lmsId!,
    //   courseLmsId: +courseID!,
    //   assignmentLmsId: +assignmentID!,
    //   questionLmsId: +questionID!,
    // })
    QuestionSubmissionsRefetch()
      .unwrap()
      .then((data) => {
        const jsonData = JSON.stringify(data, null, 2);
        const blob = new Blob([jsonData], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = `${courseID!}_${+assignmentID!}_${questionID!}.json`;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  };

  const noSumbissionsGraded = statusData
    ? statusData.questions[`${question?.id}`]
      ? statusData.questions[`${question?.id}`] === 0
      : true
    : true;

  const status = statusData
    ? statusData.questions[`${question?.id}`]
      ? statusData.questions[`${question?.id}`]
      : "0"
    : "";

  return GetLmsCourseAssignmentRubricsResults.data ? (
    <div>
      <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces`}
          >
            Workspaces
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses`}
          >
            Courses
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses/${courseID}`}
          >
            Assignments
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses/${courseID}/assignments/${assignmentID}`}
          >
            Questions
          </Link>
          <div>Question</div>
        </Breadcrumbs>
      </div>
      {isSuccess ? (
        <div>
          {/* <p>{data.task_id}</p> */}
          <GradingJobProgress
            lms_id={workingLms && workingLms.lmsId!}
            course_lms_id={+courseID!}
            assignment_lms_id={+assignmentID!}
            question_lms_id={+questionID!}
            task_id={data.task_id}
            job_id={data.id}
            // refetch={refetch}
          />
        </div>
      ) : (
        <div />
      )}
      <div>
        <Card>
          <CardHeader title="Instructions" />
          <CardContent sx={{}}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              To grade all submissions, please follow these steps:
            </Typography>
            <ol>
              <li>Specify the grading criteria in the "Rubric" section</li>
              <li>
                Grade at least 5 submissions to ensure the criteria meets your
                expectations. You can grade submissions individually or use the
                GRADE FIRST 5 option.
              </li>
              <li>
                Hit the button below to grade the first 5 or initiate the
                grading process for all submisions
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: "bold" }}>Status: </span>{" "}
              {`${status} submissions graded`}
            </p>
          </CardContent>
          <ByQuestionGradeAllDialog
            disabled={isSuccess || rubric === undefined || rubric === ""}
            refetch={refetch}
          />
          <LoadingButton
            disabled={noSumbissionsGraded}
            style={{ marginTop: "5px" }}
            fullWidth
            loading={false}
            variant="contained"
            onClick={handleDownload}
            startIcon={<GetAppIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
          >
            Download results
          </LoadingButton>
          {/* <LoadingButton
            disabled={noSumbissionsGraded}
            style={{ marginTop: "5px" }}
            fullWidth
            loading={false}
            variant="contained"
            onClick={() => {}}
            endIcon={<UploadIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
          >
            Submit Grades
          </LoadingButton> */}
          <div style={{ marginTop: "5px" }}>
            <SubmitGradesDialog
              disabled={isSuccess || noSumbissionsGraded}
              refetch={refetch}
            />
          </div>
        </Card>

        <p className="grey-title-container">
          {`Question (${question ? question.points_possible : ""} pt)`}
        </p>
        {questionIsLoading ? (
          <CircularProgress />
        ) : (
          <div
            className="white-bordred-container"
            style={{ overflow: "auto" }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(question.question_text),
            }}
          ></div>
        )}
        <p className="grey-title-container">Solution</p>
        {questionIsLoading ? (
          <CircularProgress />
        ) : (
          <div
            className="white-bordred-container"
            style={{ overflow: "auto" }}
            dangerouslySetInnerHTML={{
              __html: question.solution,
            }}
          />
        )}
        <p className="grey-title-container">Rubric</p>
        <div
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            padding: "10px",
          }}
        >
          <TextArea
            showCount
            maxLength={500}
            style={{ height: 120, resize: "none" }}
            placeholder="Provide a rubric to enforce grading criteria "
            // defaultValue={rubrics[+questionID!]}
            defaultValue={evaluateValue(+questionID!)}
            onChange={onRubricChange}
            onBlur={saveRubric}
          ></TextArea>
          <LoadingButton
            style={{ marginTop: "5px" }}
            loading={false}
            variant="contained"
            onClick={saveRubric}
          >
            Save Rubric
          </LoadingButton>
        </div>
      </div>
      <div>
        <p
          className="grey-title-container"
          style={{ textAlign: "center", color: "#555555" }}
        >
          Student Submissions
        </p>
        <div
        // className="grey-bordered-container"
        >
          {false ? (
            <CircularProgress />
          ) : (
            <QuestionSubmissionsList
              Question={question}
              // QuestionSubmissionsList={qestionSubmissionsData}
            />
          )}
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Rubric saved!
        </Alert>
      </Snackbar>
    </div>
  ) : (
    <div></div>
  );
};

export default QuestionSubmissionsPage;
