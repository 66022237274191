import React from "react";
import styled from "styled-components";

// Define the styles for the PricingPage
const PageContainer = styled.div`
  padding: 20px;
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Card = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
  text-align: center;
`;

const CardTitle = styled.h2`
  font-size: 24px;
  color: #3498db;
  margin-bottom: 10px;
`;

const CardDescription = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #3498db;
`;

const PricingPage: React.FC = () => {
  return (
    <div className="gradient-background">
      <PageContainer>
        <TitleContainer>Our Pricing Model</TitleContainer>
        <CardContainer>
          <Card>
            <CardTitle>No Upfront Cost</CardTitle>
            <CardDescription>
              Start using InteliGrade with no initial investment. Pay only for
              what you use with our flexible pricing model.
            </CardDescription>
          </Card>
          <Card>
            <CardTitle>Pay-as-You-Go</CardTitle>
            <CardDescription>
              Enjoy the flexibility of our <Highlight>pay-as-you-go</Highlight>{" "}
              pricing. Only pay for the grading services based on your usage.
            </CardDescription>
          </Card>
          <Card>
            <CardTitle>Block Fee</CardTitle>
            <CardDescription>
              Our base fee includes a certain number of students, assignments,
              and questions per course. Perfect for getting started.
            </CardDescription>
          </Card>
          <Card>
            <CardTitle>Extra Requests Fee</CardTitle>
            <CardDescription>
              Need more? Pay for additional requests based on the number and
              complexity of the grading tasks. Scale as you grow.
            </CardDescription>
          </Card>
        </CardContainer>
      </PageContainer>
    </div>
  );
};

export default PricingPage;
