import React from "react";
import CWL from "./cwlAuth";
import logo from "../assets/lms_icon.png";
import { fontSize } from "@mui/system";
import { Divider } from "@mui/material";
import { ENVIRONMENT } from "../config";

interface Props {
  children: React.ReactNode;
}

const HomePage: React.FC = () => {
  return (
    <div className="gradient-background" style={{ textAlign: "center" }}>
      <div style={{ margin: "0px" }}>
        <div style={{ padding: "20px" }}>
          <img
            src={logo}
            width={200}
            style={{ display: "inline-block", verticalAlign: "middle" }}
          ></img>
          <div
            style={{
              // display: "inline-block",
              verticalAlign: "middle",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "Bruno Ace SC",
                fontSize: "50px",
                margin: "5px",
                wordSpacing: "-20px",
              }}
            >
              Inteli Grade
            </p>
            {/* <p style={{ fontSize: "20px", marginLeft: "5px" }}>
              {`The ultimate LMS support solution ${
                ENVIRONMENT === "test" ? "(TEST)" : ""
              }`}
            </p> */}
            {/* <p>ENV: {ENVIRONMENT}</p> */}
          </div>
        </div>
      </div>
      <div
        // className="grey-title-container"
        style={{
          fontSize: "50px",
          fontWeight: "bold",
          marginTop: "50px",
          marginBottom: "10px",
          background:
            "linear-gradient(to right, rgb(0 50 97), rgb(52 152 219), rgb(150 28 207), rgb(23 115 169))",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          whiteSpace: "pre-line",
        }}
      >
        The Cutting-Edge AI-Powered Grading System Revolutionizing Exam
        Assessment {"\n"}
        {/* Experience the Complete Automation of the Grading Process Like Never
        Before! */}
      </div>

      {/* <div
        // className="white-bordred-container"
        style={{
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        The Cutting-Edge AI-Powered Grading System Revolutionizing Exam
        Assessment! Experience the Complete Automation of the Grading Process
        Like Never Before!
      </div> */}
      {/* <Divider sx={{ marginTop: "20px", borderBottomWidth: "5px" }} /> */}
      <div
        style={{
          fontSize: "40px",
          fontWeight: "bold",
          marginTop: "50px",
          marginBottom: "10px",
          color: "#3498DB",
        }}
      >
        <Divider>Why InteliGrade?</Divider>
      </div>
      <div
        style={{
          fontSize: "20px",
          textAlign: "center",
          whiteSpace: "pre-line",
        }}
      >
        <p style={{ fontSize: "30px", fontWeight: "bold", color: "#34495E" }}>
          Speed and Scalability {"\n"}
        </p>
        <p style={{ fontSize: "25px" }}>
          InteliGrade empowers the rapid evaluation of tens of thousands of exam
          submissions within a matter of minutes, enabling expedited grading
          processes and facilitating the prompt delivery of immediate results to
          students
        </p>
        <p style={{ fontSize: "30px", fontWeight: "bold", color: "#34495E" }}>
          Accuracy and Clarity {"\n"}
        </p>
        <p style={{ fontSize: "25px" }}>
          Through its provision of precise and comprehensive feedback to
          students, InteliGrade significantly enhances transparency and elevates
          the overall quality of education
        </p>
        <p style={{ fontSize: "30px", fontWeight: "bold", color: "#34495E" }}>
          Versatility {"\n"}
        </p>
        <p style={{ fontSize: "25px" }}>
          InteliGrade exhibits a remarkable capacity to effectively process and
          evaluate diverse essay type questions, including but not limited to
          discussions, reasoning exercises, and open-ended inquiries. Moreover,
          it possesses the ability to assess coding questions, even in the
          absence of a reference solution
        </p>
        <p style={{ fontSize: "30px", fontWeight: "bold", color: "#34495E" }}>
          Multilanguage Support {"\n"}
        </p>
        <p style={{ fontSize: "25px" }}>
          InteliGrade boasts language-agnostic capabilities, facilitating the
          seamless implementation of exam evaluation processes across a wide
          range of languages
        </p>
        <p style={{ fontSize: "30px", fontWeight: "bold", color: "#34495E" }}>
          AI Content Detection {"\n"}
        </p>
        <p style={{ fontSize: "25px" }}>
          The proliferation of generative AI models has significantly heightened
          the complexity of manual human assessment, increasing the risk of
          academic dishonesty through cheating. However, InteliGrade offers a
          reliable solution to this challenge by effectively discerning whether
          students' responses have been artificially generated by AI systems
        </p>
      </div>
      <Divider sx={{ marginTop: "20px", borderBottomWidth: "5px" }} />
      <div
        // className="grey-title-container"
        style={{
          fontSize: "15px",
          fontWeight: "bold",
          marginTop: "50px",
          marginBottom: "15px",
        }}
      >
        InteliGrade currently supports the following Learning Management Systems
      </div>
      <div
      // className="white-bordred-container"
      >
        <ul>
          {/* <li>Canvas</li> */}

          <img
            style={{ width: 150 }}
            src="https://www.instructure.com/sites/default/files/image/2021-12/Canvas_Horizontal_ByInstructure_Color_RGB.png"
          />
        </ul>
      </div>
      {/* <CWL /> */}
    </div>
  );
};

export default HomePage;
